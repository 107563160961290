import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { format, intervalToDuration, subDays } from 'date-fns'
import { Gantt, Task, ViewMode } from 'gantt-task-react'
import 'gantt-task-react/dist/index.css'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { Button } from './components/Button'
import { DtDialog } from './components/Dialog'
import { post } from './helpers/api.ts'
import { isChangeRequest } from './helpers/types.ts'
import useChangeRequests from './hooks/useChangeRequests'
import useDirectus from './hooks/useDirectus'
import { calculateProgressPercentage } from './hooks/useSteps'
import { currentChangeRequestState } from './state/current-change-request-state'
import { viewModeColumnWidthState } from './state/view-mode-col-width-state'
import { viewModeState } from './state/view-mode-state'

const viewModeSets = [
  { name: 'Hour', viewMode: ViewMode.Hour, columnWidth: 65 },
  { name: 'Day', viewMode: ViewMode.Day, columnWidth: 65 },
  { name: 'Week', viewMode: ViewMode.Week, columnWidth: 250 },
  { name: 'Month', viewMode: ViewMode.Month, columnWidth: 300 },
]

const formatTime = (date: Date) => format(date, 'yyyy-MM-dd HH:mm')
const formatDuration = (start: Date, end: Date) => {
  const { hours = 0, minutes = 0 } = intervalToDuration({ start, end })
  return hours ? `${hours}h ${minutes}m` : `${minutes}m`
}

const Chart = () => {
  const headers = useRef<HeadersInit | undefined>()
  const directus = useDirectus()

  const [changeRequests, getChangeRequest] = useChangeRequests()
  const [changeRequestExpandCollapseState, setChangeRequestExpandCollapseState] = useState<{
    [changeRequestId: string]: boolean
  }>({})
  const [viewMode, setViewMode] = useRecoilState(viewModeState)
  const [columnWidth, setColumnWidth] = useRecoilState(viewModeColumnWidthState)
  const [currentChangeRequest, setCurrentChangeRequest] = useRecoilState(currentChangeRequestState)

  useEffect(() => {
    if (directus === null) return
    getChangeRequest()
  }, [directus])

  useEffect(() => {
    if (!changeRequests[0]) return
    const updatedCurrentChangeRequestIndex = changeRequests.findIndex((changeRequest) => changeRequest.id === currentChangeRequest?.id)
    if (updatedCurrentChangeRequestIndex !== -1) {
      setCurrentChangeRequest(changeRequests[updatedCurrentChangeRequestIndex])
      setChangeRequestExpandCollapseState({
        ...changeRequestExpandCollapseState,
        [changeRequests[updatedCurrentChangeRequestIndex].id]: true,
      })
      return
    }
    setCurrentChangeRequest(changeRequests[0])
    setChangeRequestExpandCollapseState({
      ...changeRequestExpandCollapseState,
      [changeRequests[0].id]: true,
    })
  }, [changeRequests])

  useEffect(() => {
    if (!currentChangeRequest?.id) return
    setViewMode(autoView(currentChangeRequest.start, currentChangeRequest.end) || ViewMode.Day)
  }, [currentChangeRequest?.id])

  const preStepsCount = 1

  const autoView = (start: Date, end: Date) => {
    if (!start || !end || viewMode === ViewMode.Hour) return
    const durationInMilliseconds = end.getTime() - start.getTime()
    const durationInDays = durationInMilliseconds / (1000 * 60 * 60 * 24) // Convert milliseconds to days
    switch (true) {
      case durationInDays < 1:
        setColumnWidth(65)
        return ViewMode.Hour
      case durationInDays < 7:
        setColumnWidth(65)
        return ViewMode.Day
      case durationInDays < 30:
        setColumnWidth(250)
        return ViewMode.Week
      default:
        setColumnWidth(300)
        return ViewMode.Month
    }
  }

  function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(' ')
  }

  const handleBeginRollback = () => {
    post({
      url: `/api/rollback/rollback-initiation/${currentChangeRequest?.id.replace('CR', '')}`,
      headers: headers.current,
      onSuccess: getChangeRequest,
    })
  }

  const handleDuplicateChangeRequest = () => {
    const offset = 120
    post({
      url: `/api/change-request/duplicate/${currentChangeRequest?.id.replace('CR', '')}?offset=${offset}`,
      headers: headers.current,
      onSuccess: getChangeRequest,
    })
  }

  const stepsMappedToChart = useMemo(() => {
    if (!currentChangeRequest) return []

    const changeRequestProject = [
      currentChangeRequest,
      ...(changeRequestExpandCollapseState[currentChangeRequest.id] ? currentChangeRequest.steps : []),
    ]
    if (currentChangeRequest.rollbackedAt && currentChangeRequest.rollbackSteps.length) {
      const rollbackStart = currentChangeRequest.rollbackedAt
      const rollbackEnd = currentChangeRequest.rollbackSteps[currentChangeRequest.rollbackSteps.length - 1].end
      const rollbackChangeRequestProject = {
        ...currentChangeRequest,
        id: `RB_${currentChangeRequest.id}`,
        name: `Rollback: ${currentChangeRequest.name}`,
        start: rollbackStart,
        end: rollbackEnd,
        progress: calculateProgressPercentage(rollbackStart, rollbackEnd),
      }
      if (changeRequestExpandCollapseState[rollbackChangeRequestProject.id] === undefined) {
        setChangeRequestExpandCollapseState({
          ...changeRequestExpandCollapseState,
          [rollbackChangeRequestProject.id]: true,
        })
      }
      const currentChangeRequestRollbackProject = [
        rollbackChangeRequestProject,
        ...(changeRequestExpandCollapseState[rollbackChangeRequestProject.id] ? currentChangeRequest.rollbackSteps : []),
      ]
      return [...changeRequestProject, ...currentChangeRequestRollbackProject]
    }
    return changeRequestProject
  }, [currentChangeRequest, changeRequestExpandCollapseState])

  const expandCollapseChangeRequest = (changeRequest: Task) => {
    setChangeRequestExpandCollapseState({
      ...changeRequestExpandCollapseState,
      [changeRequest.id]: !changeRequestExpandCollapseState[changeRequest.id],
    })
  }

  const handleCompleteTask = (id: string) => {
    post({
      url: `/api/task/${id}/complete`,
      headers: headers.current,
      onSuccess: getChangeRequest,
    })
  }

  type SelectedTask = Task & { completedAt?: Date }

  const [selectedTask, setSelectedTask] = useState<SelectedTask | null>(null)
  const closeTaskDialog = () => setSelectedTask(null)

  const completeStep = async () => {
    closeTaskDialog()

    if (!selectedTask) return
    if (isChangeRequest(selectedTask)) {
      //
    } else {
      // Task | Milestone
      handleCompleteTask(selectedTask.id)
    }
  }

  return (
    <div className="mx-4">
      <div className="dashboard-header-container mb-4">
        {currentChangeRequest && (
          <div className="flex h-28">
            <h1 className="self-center text-5xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">{currentChangeRequest.name}</h1>
          </div>
        )}
        <div className="flex justify-between">
          <div className="flex flex-col">
            {currentChangeRequest?.rollbackedAt ? (
              <span>Rollbacked at: {format(currentChangeRequest.rollbackedAt, 'dd/LL/yyyy-HH:m:ss')}</span>
            ) : (
              <button
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleBeginRollback}
              >
                Begin rollback
              </button>
            )}
            {currentChangeRequest?.rollbackedAt && !currentChangeRequest.duplicate ? (
              <button
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleDuplicateChangeRequest}
              >
                Duplicate change request {currentChangeRequest.duplicate}
              </button>
            ) : currentChangeRequest?.rollbackedAt && currentChangeRequest.duplicate ? (
              <div>
                Duplicate:{' '}
                <a
                  className="text-link transition duration-150 ease-in-out hover:text-link-hover focus:text-link-hover cursor-pointer"
                  onClick={() => setCurrentChangeRequest(changeRequests.find((cr) => cr.id === `CR${currentChangeRequest.duplicate}`))}
                >
                  {changeRequests.find((cr) => cr.id === `CR${currentChangeRequest.duplicate}`)?.name}
                </a>
              </div>
            ) : null}
            {currentChangeRequest?.parent && (
              <div>
                Parent:{' '}
                <a
                  className="text-link transition duration-150 ease-in-out hover:text-link-hover focus:text-link-hover cursor-pointer"
                  onClick={() => setCurrentChangeRequest(changeRequests.find((cr) => cr.id === `CR${currentChangeRequest.parent}`))}
                >
                  {changeRequests.find((cr) => cr.id === `CR${currentChangeRequest.parent}`)?.name}
                </a>
              </div>
            )}
          </div>
          <div className="flex flex-row gap-4">
            {currentChangeRequest?.risk && (
              <div className="flex mt-auto items-center">
                <div className="relative inline-block tooltip">
                  <img src="/info.png" className="w-5 h-5 cursor-pointer" />
                  <span className="tooltiptext">
                    <p>
                      Risk management is the process of identifying, assessing and controlling risks that a change faces. A risk score of{' '}
                      <span className="text-green-500">0-30</span> is considered low, <span className="text-yellow-500">31-60</span> is medium and{' '}
                      <span className="text-red-500">61-100</span> is high.
                    </p>
                  </span>
                </div>
                <p
                  className={classNames(
                    currentChangeRequest.risk <= 30 && 'text-green-500',
                    currentChangeRequest.risk >= 31 && currentChangeRequest.risk <= 60 && 'text-yellow-500',
                    currentChangeRequest.risk >= 61 && currentChangeRequest.risk <= 100 && 'text-red-500',
                    `px-[4px] py-[8px] rounded-md text-sm font-medium`,
                  )}
                >
                  Risk: {currentChangeRequest.risk}
                </p>
              </div>
            )}
            <div className="flex">
              <nav className="flex space-x-4 mt-auto rounded-md bg-[#e5e7eb]" aria-label="Tabs">
                {viewModeSets.map((view) => (
                  <a
                    onClick={() => {
                      setViewMode(view.viewMode)
                      setColumnWidth(view.columnWidth)
                    }}
                    key={view.name}
                    className={classNames(
                      view.name === viewMode
                        ? 'bg-gray-100 text-gray-700 border-2 border-[#e5e7eb] px-[10px] py-[6px]'
                        : 'text-gray-500 hover:text-gray-700 px-[12px] py-[8px]',
                      'rounded-md text-sm font-medium cursor-pointer',
                    )}
                  >
                    {view.name}
                  </a>
                ))}
              </nav>
            </div>
            <Menu as="div" className="flex relative text-left">
              <div className="mt-auto">
                <Menu.Button className="inline-flex gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <p className="w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">{currentChangeRequest?.name}</p>
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute max-h-44 overflow-y-auto right-0 z-10 top-9 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {changeRequests
                      .sort((a, b) => b.steps.length - a.steps.length)
                      .map((changeRequest) => (
                        <Menu.Item key={changeRequest.id}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                if (changeRequest.steps.length > 0) {
                                  setCurrentChangeRequest(changeRequest)
                                }
                              }}
                              href="#"
                              className={classNames(
                                active && changeRequest.steps.length > 0 && 'bg-gray-100 text-gray-900',
                                'block px-4 py-2 text-sm',
                                changeRequest.steps.length === 0 ? 'cursor-default text-[#e6e4e4] bg-[#f5f5f5]' : 'text-gray-700',
                              )}
                            >
                              {changeRequest.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      {/* Gantt Chart */}
      {currentChangeRequest && (
        <Gantt
          onExpanderClick={expandCollapseChangeRequest}
          viewDate={subDays(new Date(currentChangeRequest.start), 1)}
          tasks={stepsMappedToChart}
          onClick={(task) => !isChangeRequest(task) && setSelectedTask(task)}
          {...{ viewMode, preStepsCount, columnWidth }}
          TooltipContent={({ task, fontSize }) => {
            return (
              <div className={`bg-gray-50 p-3 text-[${fontSize}] rounded shadow-xl`}>
                <p className="font-bold">
                  {task.name}: {format(task.start, 'd-M-yyyy')} - {format(task.end, 'd-M-yyyy')}
                </p>
                <p className="text-sm text-neutral-400">Duration: {formatDuration(task.start, task.end)}</p>
                <p className="text-sm text-neutral-400">Progress: {task.progress} %</p>
              </div>
            )
          }}
        />
      )}

      {/* Selected Task Dialog */}
      {selectedTask && (
        <DtDialog isOpen>
          <Dialog.Title className="font-bold pb-1">
            <div className="flex justify-between gap-5">
              <div>{selectedTask.name}</div>
              <div>[{selectedTask.type}]</div>
            </div>
          </Dialog.Title>
          {/*<Dialog.Description className="text-sm"></Dialog.Description>*/}

          <div className="py-5">
            <p className="text-sm">{`${formatTime(selectedTask.start)} - ${formatTime(selectedTask.end)}`}</p>
          </div>

          {selectedTask.completedAt ? (
            <>
              <p>This step has been completed at {formatTime(selectedTask.completedAt)}</p>

              <div className="flex justify-end gap-3 mt-2">
                <Button onClick={closeTaskDialog}>Back</Button>
              </div>
            </>
          ) : (
            <>
              <p>Are you sure you want to complete this {selectedTask.type}?</p>

              <div className="flex justify-end gap-3 mt-2">
                <Button onClick={closeTaskDialog}>Cancel</Button>
                <Button onClick={completeStep} variant="primary">
                  Complete
                </Button>
              </div>
            </>
          )}
        </DtDialog>
      )}
    </div>
  )
}

export default Chart
