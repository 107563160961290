import { Link } from 'react-router-dom'
import { Button } from './components/Button'

const UserPortal = () => {
  return (
    <div className="bg-gradient-to-r from-[#1b8759] to-[#83dab5] h-[100vh] bg-cover bg-fixed flex flex-col justify-center items-center  m-auto gap-6">
      <div className="w-5/12 gap-8 rounded-xl bg-black py-5 text-white flex flex-col justify-center items-center ">
        <h1 className="text-3xl font-bold">Welcome to Deployment Team</h1>
        <div className="flex flex-col justify-center items-center items-start gap-4">
          <div className="flex flex-row justify-center items-center gap-4">
            <p>Already have an account?</p>
            <Button className="bg-primary font-bold" onClick={() => (window.location.href = `${origin}/api/auth/login/google?redirect=${origin}`)}>
              Login
            </Button>
          </div>
          <div className="flex flex-row justify-center items-center gap-4">
            <p>New to the platform?</p>
            <Button className="font-bold">
              <Link className="text-black" to="/invite">
                Sign up
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserPortal
