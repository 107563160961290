import { authentication, AuthenticationClient, createDirectus, DirectusClient, rest, RestClient } from '@directus/sdk'
import { useState } from 'react'
import { ChangeRequestDTO } from '../models/change-request.dto'
import { RollbackStepDTO } from '../models/rollback-step.dto'
import { StepDTO } from '../models/step.dto'

export interface Schema {
  change_requests: ChangeRequestDTO[]
  steps: StepDTO[]
  rollback_steps: RollbackStepDTO[]
}

export type DirectusClientType = DirectusClient<Schema> & RestClient<Schema> & AuthenticationClient<Schema>

const msRefreshBeforeExpires = 1000 * 60 * 60 * 3
export const useDirectus = () => {
  const [directus, setDirectus] = useState<DirectusClientType | null>(null)

  const client =
    directus ??
    createDirectus<Schema>(location.origin + "/api")
      .with(rest())
      .with(
        authentication('json', {
          autoRefresh: true,
          msRefreshBeforeExpires,
        }),
      )

  const storedAuth = window.localStorage.getItem('authData')
  let existingAuth = storedAuth ? JSON.parse(storedAuth) : null

  if (existingAuth) {
    const expires = Number(existingAuth.expires_at) - msRefreshBeforeExpires > Date.now()

    if (expires) {
      existingAuth = null
      window.localStorage.removeItem('authData')
    }
  }

  if (!existingAuth) {
    client
      .refresh()
      .then((authData) => {
        client.setToken(authData.access_token)
        window.localStorage.setItem('authData', JSON.stringify(authData))
      })
      .catch((error) => {
        console.error('error refreshing token', error)
      })
  } else {
    client.setToken(existingAuth.access_token)
  }
  !directus && setDirectus(client)

  return directus
}

export default useDirectus
