import { readItem, readItems } from '@directus/sdk'
import { addMinutes, compareAsc, compareDesc } from 'date-fns'
import { Task } from 'gantt-task-react'
import { RollbackStepDTO } from '../models/rollback-step.dto'
import { StepDTO } from '../models/step.dto'
import { DirectusClientType } from './useDirectus'

// From 0 to 100 in percentage
export const calculateProgressPercentage = (start?: Date, end?: Date) => {
  if (!start || !end) return 0
  const today = new Date()
  const total = end.getTime() - start.getTime()
  const elapsed = today.getTime() - start.getTime()
  const progress = Math.floor((elapsed / total) * 100)
  const progressInPercentage = Math.min(100, Math.max(0, progress))
  return progressInPercentage
}
export const fetchSteps = (changeRequestId: string, directus: DirectusClientType) => {
  const steps = directus
    .request(readItems('steps', { limit: -1, filter: { change_request_id: { _eq: changeRequestId } } }))

    .then((data) => {
      return data?.map((step) => {
        const start = new Date(step.start)
        const end = new Date(step.end)
        return {
          id: step.id.toString(),
          name: step.name,
          risk: (step.risk_probability + step.risk_consequence) * 5,
          type: step.type === 'normal' ? 'task' : 'milestone',
          progress: calculateProgressPercentage(start, end),
          project: 'CR' + step.change_request_id,
          completedAt: step.completed_at && new Date(step.completed_at),
          start,
          end,
        } as Task
      })
    })
    .then((loadedSteps) => loadedSteps?.sort((a, b) => compareAsc(new Date(a.start), new Date(b.start))) || [])
  return steps
}

export const fetchRollbackSteps = async (changeRequestId: string, directus: DirectusClientType) => {
  const changeRequest = await directus.request(readItem('change_requests', changeRequestId))
  if (!changeRequest?.rollbacked_at) return []
  const steps = await directus.request(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    readItems('steps', { filter: { change_request_id: { _eq: changeRequestId }, end: { _lte: '$NOW(+3 hours)' } }, limit: -1, sort: ['-start'] }),
  )
  const milestoneStepIndex = steps.findIndex((step: StepDTO) => step.type === 'milestone')
  let completedStepsToRollback = steps
  if (milestoneStepIndex !== -1) {
    completedStepsToRollback = steps?.slice(0, milestoneStepIndex)
  }
  if (!completedStepsToRollback || completedStepsToRollback.length === 0) return []
  const rollbackSteps = await directus.request(
    readItems('rollback_steps', {
      limit: -1,
      filter: {
        related_step_id: {
          _in: completedStepsToRollback?.map((step: StepDTO) => step.id) || [],
        },
      },
    }),
  )

  const sortedRollbackSteps = rollbackSteps?.sort((a: RollbackStepDTO, b: RollbackStepDTO) => {
    const aStep = completedStepsToRollback?.find((step) => step.id === a.related_step_id)
    const bStep = completedStepsToRollback?.find((step) => step.id === b.related_step_id)
    if (!aStep || !bStep) return 0
    return compareDesc(new Date(aStep.start), new Date(bStep.start))
  })

  return (
    sortedRollbackSteps?.reduce((acc, rollbackStep) => {
      const prevRollbackStep = acc[acc.length - 1]
      let start: Date
      let end: Date
      if (!prevRollbackStep) {
        start = new Date(changeRequest.rollbacked_at)
        end = addMinutes(new Date(changeRequest?.rollbacked_at), rollbackStep.duration)
      } else {
        start = new Date(prevRollbackStep.end)
        end = addMinutes(start, rollbackStep.duration)
      }
      return [
        ...acc,
        {
          id: `RB${rollbackStep.id.toString()}`,
          name: rollbackStep.name,
          type: 'task',
          progress: calculateProgressPercentage(start, end),
          project: 'RB_CR' + changeRequestId,
          start,
          end,
        } as Task,
      ]
    }, [] as Task[]) || []
  )
}
