import { cva } from 'class-variance-authority'

export const buttonStyles = cva(["transition-colors"], {
  variants: {
    variant: {
      default: ["bg-secondary", "hover:bg-secondary-hover"],
      primary: ["bg-primary hover:bg-primary-hover text-white"],
    },
    size: {
      default: ["rounded", "p-2"],
      big: ["rounded", "p-3"],
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
})
