import { parseISO } from 'date-fns'
import { Task } from 'gantt-task-react'

type ChangeRequest = Task & {
  type: 'project'
}

export const isChangeRequest = (task: Task): task is ChangeRequest => task.type === 'project'

export const parseJSONWithStringToDate = <T extends string>(data: string, keysToConvertToDate: T[] = []) =>
  JSON.parse(data, (k: string, v) => ([...keysToConvertToDate].includes(k as T) ? parseISO(v) : v))
