import { useRef, useState } from 'react'
import { Button } from './components/Button'
import { post } from './helpers/api.ts'

const Invite = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const headers = useRef<HeadersInit>()
  const emailRef = useRef<HTMLInputElement>(null)

  const handleInvite = () => {
    const email = emailRef.current?.value

    post({
      url: `api/email-verification/${email}/register`,
      headers: headers.current,
      onSuccess: () => setShowSuccessModal(true),
      onError: (error) => console.log(error),
    })
  }
  return (
    <div className="bg-gradient-to-r from-[#1b8759] to-[#83dab5] h-[100vh] bg-cover bg-fixed flex flex-col justify-center items-center  m-auto gap-6">
      <div className="w-5/12 gap-8 rounded-xl bg-black px-5 py-5 text-white flex flex-col justify-center items-center ">
        {!showSuccessModal ? (
          <>
            <h1 className="text-3xl font-bold">Register</h1>
            <div className="flex flex-col justify-center items-center gap-4">
              <p>Register through email</p>
              <div>
                <input ref={emailRef} type="email" className="border-2 text-black border-black rounded-md p-2" placeholder="Email"></input>{' '}
                <Button
                  className="font-bold text-black"
                  onClick={() => {
                    handleInvite()
                  }}
                >
                  Register
                </Button>
              </div>
              <div className="relative w-full flex justify-center">
                <hr className="absolute w-full top-1/2 z-1"></hr>
                <p className="bg-black p-2 z-10">or</p>
              </div>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <Button
                  className="bg-primary w-full font-bold"
                  onClick={() => (window.location.href = `${origin}/api/auth/login/google?redirect=${origin}`)}
                >
                  Register with Google
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <h1 className="text-3xl font-bold text-center">Your invitation has been sent successfully!</h1>
            <div className="flex flex-col justify-center items-center items-start gap-4">
              <p className="text-center">Please check your email to proceed with the registration process.</p>
              <div className="flex flex-row w-full justify-center items-center gap-4">
                <Button className="bg-primary w-full font-bold" onClick={() => setShowSuccessModal(false)}>
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Invite
