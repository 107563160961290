import { PropsWithChildren } from 'react'
import { Dialog } from '@headlessui/react'

type Props = {
  isOpen: boolean
  onClose?: () => void
}

export const DtDialog = ({ isOpen, onClose = () => null, children }: PropsWithChildren<Props>) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className='relative z-50'
    >
      {/* Backdrop */}
      <div className='fixed inset-0 bg-black/30' aria-hidden='true' />

      <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
        <Dialog.Panel className='bg-white p-5 rounded'>
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
