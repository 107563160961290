type PostParams = {
  url: string
  headers?: HeadersInit
  body?: BodyInit | null
  onSuccess?: (res: Response) => void
  onError?: (error: Error) => void
}
export const post = ({ url, headers, body, onSuccess, onError }: PostParams) => {
  fetch(url, {
    method: 'POST',
    headers,
    body,
  })
    .then((res) => {
      if (!res.ok) {
        return res.json().then((err) => {
          throw err
        })
      }
      onSuccess?.(res)
    })
    .catch((error) => {
      console.log('HAYAA')
      console.error(error + 'is this it')
      onError?.(error)
    })
}
