import { readItems } from '@directus/sdk'
import { Task } from 'gantt-task-react'
import { useState } from 'react'
import { ChangeRequest } from '../models/change-request.interface'
import useDirectus from './useDirectus'
import { calculateProgressPercentage, fetchRollbackSteps, fetchSteps } from './useSteps'

const useChangeRequests = () => {
  const [changeRequests, setChangeRequests] = useState<ChangeRequest[]>([])
  const directus = useDirectus()
  const earliestDate = (steps: Task[]) => {
    return steps
      .map((step) => step.start)
      .sort((a, b) => a.getTime() - b.getTime())
      .at(0)
  }
  const latestDate = (steps: Task[]) => {
    return steps
      .map((step) => step.end)
      .sort((a, b) => b.getTime() - a.getTime())
      .at(0)
  }
  console.log({ directus })
  const getChangeRequests = async () => {
    try {
      const data = await directus?.request(readItems('change_requests', { limit: 25 }))
      console.log({ data })
      const updatedChangeRequests = (data || []).map(async (changeRequest) => {
        const steps = directus === null ? [] : await fetchSteps(changeRequest.id.toString(), directus) // Call getSteps with the change request ID
        console.log({ steps })
        return {
          start: earliestDate(steps) || new Date(),
          end: latestDate(steps) || new Date(),
          id: 'CR' + changeRequest.id.toString(),
          name: changeRequest.name,
          progress: calculateProgressPercentage(earliestDate(steps), latestDate(steps)),
          type: 'project' as const,
          hideChildren: false,
          steps,
          risk: changeRequest.risk,
          duplicate: changeRequest.duplicate,
          parent: changeRequest.parent,
          rollbackedAt: changeRequest.rollbacked_at ? new Date(changeRequest.rollbacked_at) : undefined,
          rollbackSteps: changeRequest.rollbacked_at && directus ? await fetchRollbackSteps(changeRequest.id.toString(), directus) : [],
        }
      })
      const resolvedChangeRequests = await Promise.all(updatedChangeRequests)
      setChangeRequests(resolvedChangeRequests)
      console.log({ changeRequests })
    } catch (error) {
      // Handle error here
      console.error(error)
    }
  }

  return [changeRequests, getChangeRequests] as const
}

export default useChangeRequests
